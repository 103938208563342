import React, { useEffect, useRef } from 'react'
import './index.css'
import ProgressBar from '../../../ProgressBar'
import { useNavigate } from 'react-router-dom'
import Button from '../../../buttons/Button'
import { ContentRestrictionsType, GateType, MediaItemFieldsFragment } from '../../../../graphql'
import ActionButtons from '../../../../common/ActionButtons'
import getPaywallText from '../../../../utils/paywallText'
import useLiveNow from '../../../../utils/hooks/useLiveNow'

type SlideProps = {
  mediaItem: MediaItemFieldsFragment | null | undefined
}

const Slide = ({ mediaItem }: SlideProps) => {
  const navigate = useNavigate()
  const liveNow = mediaItem ? useLiveNow(mediaItem.id) : null

  const hugImgRef = useRef<HTMLDivElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)

  const item = mediaItem

  if (item) {
    const type = item?.__typename?.toLowerCase()

    //const now = new Date()
    //const startTime = new Date(item?.scheduledStart ? item?.scheduledStart : null)

    //const duration = item.duration || 0

    let header = type === 'event' ? 'PAST EVENT' : 'VIDEO'

    let eventStatus: 'live' | 'upcoming' | 'past' | undefined
    let timeRemainingText = ''
    let upcomingText = ''
    let durationText = ''
    if (liveNow) {
      timeRemainingText = liveNow.minutesRemainingText
      //const timeElapsedText = liveNow.minutesElapsedText

      eventStatus = 'live'
      header = 'ONGOING EVENT'
    } else if (item.__typename === 'Event') {
      const now = new Date()
      const start = new Date(item.scheduledStart)
      const end = new Date(item.scheduledEnd)

      durationText += `${start.toLocaleString('default', {
        month: 'long',
      })} ${start.getDate()}, ${now.getFullYear()}`

      if (now < start) {
        header = 'UPCOMING EVENT'
        eventStatus = 'upcoming'
        upcomingText = `Streaming live on ${start.toLocaleString('default', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })}`
      } else if (now > end) {
        eventStatus = 'past'
      } else {
        eventStatus = 'live'
      }
    }

    /*if (itemStatus === 'past' || itemStatus === 'video') {
      timeLeft = (duration - progress) / 60
    }

    if (itemStatus === 'live' || item?.liveNow) {
      timeLeft = Math.abs(+duration - (+now - +startTime) / 1000) / 60
      progress = ((+now - +startTime) / 1000 / duration) * 100
    } else {
      progress = (progress / duration) * 100
    }

    timeLeft = +timeLeft.toFixed(0)*/

    //const minutes = Math.floor(duration / 60)
    //const seconds = duration - minutes * 60

    //if (minutes) durationText += minutes.toString() + 'm'
    //if (seconds) durationText += ' ' + seconds.toString() + 's'

    const progressBarTopText = eventStatus === 'live' ? 'Streaming live now' : durationText

    const paywallInfo = getPaywallText(mediaItem, true)

    let progress = eventStatus !== 'upcoming' ? item.viewer?.progress || liveNow?.progress || 0 : 0

    if (liveNow && item && (!('scheduledEnd' in item) || ('scheduledEnd' in item && !item?.scheduledEnd))) {
      progress = 1
      timeRemainingText = ''
    }

    useEffect(() => {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          console.log('Image resize', entry)
          // Set hugImg to hug the actual layout of the image
          if (hugImgRef.current) {
            hugImgRef.current.style.width = entry.contentRect.width + 'px'
            hugImgRef.current.style.height = entry.contentRect.height + 'px'
          }
        }
      })

      if (imgRef.current) {
        resizeObserver.observe(imgRef.current)
      }

      return () => {
        // Cleanup the observer when the component unmounts
        if (imgRef.current) {
          resizeObserver.unobserve(imgRef.current)
        }
      }
    }, [])

    return (
      <div className={'slideContainer'} style={styles.slideContainer}>
        <div className={'slide slideLeft'} style={styles.slideLeft}>
          <div className={'slideContent'} style={styles.slideContent}>
            <div className={'slideHeaderContainer'} style={styles.slideHeaderContainer}>
              <span className={'slideHeader'}>{header}</span>
              <div className={'slideHeaderDivider'} />
            </div>
            {item?.logo?.src ? (
              <div className={'slideLogoContainer'}>
                <img src={item?.logo?.src} alt={item?.logo?.src} className={'slideLogo'} />
              </div>
            ) : (
              <div className={'slideTitleContainer'}>
                <span className={'slideTitle'}>{item?.name}</span>
              </div>
            )}
            <span className={'slideDescription'}>{item?.description}</span>
            <div className={'slideInfoContainer'}>
              <div className={'slideBtnContainer'} style={styles.slideBtnContainer}>
                <ActionButtons mediaItem={mediaItem} progress={progress} onSlider />
                <Button text={'details'} width={100} height={36} onClick={() => navigate(`/content/${item?.id}`)} />
              </div>
              <div className={'slideInfo'} style={styles.slideInfo}>
                {paywallInfo?.text?.length ? (
                  <span className={'slideUpcomingText'} style={styles.slideUpcomingText}>
                    {mediaItem?.appliedGates.find((g) => g.type === GateType.Ppv) &&
                      mediaItem?.appliedGates.find((g) => g.type === GateType.Subscription) &&
                      paywallInfo?.text}
                  </span>
                ) : (
                  <>
                    {eventStatus === 'upcoming' ? (
                      <span className={'slideUpcomingText'} style={styles.slideUpcomingText}>
                        {upcomingText}
                      </span>
                    ) : progress ? (
                      <div className={'slideProgressBarWrapper'} style={styles.slideProgressBarWrapper}>
                        {progressBarTopText && (
                          <span className={'slideProgressBarTopText'} style={styles.slideProgressBarTopText}>
                            {progressBarTopText}
                          </span>
                        )}
                        <div className={'slideProgressBarContainer'} style={styles.slideProgressBarContainer}>
                          <div
                            style={{
                              flex: 1,
                              alignItems: 'center',
                              position: 'relative',
                              justifyContent: 'center',
                              maxWidth: 150,
                              height: 6,
                            }}>
                            <ProgressBar progress={progress} live={eventStatus === 'live'} />
                          </div>
                          {timeRemainingText && (
                            <div className={'slideProgressBarText'} style={styles.slideProgressBarText}>
                              {timeRemainingText}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <span className={'slideDurationText'} style={styles.slideDurationText}>
                        {durationText}
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={'slide slideRight'} style={styles.slideRight}>
          <div className={'slideImgContainer'} style={styles.slideImgContainer}>
            <img
              ref={imgRef}
              src={item?.sixteenNineCover?.src}
              alt={''}
              className={'slideImg'}
              style={styles.slideImg}
            />
            <div ref={hugImgRef} style={{ position: 'absolute' }}>
              {eventStatus === 'live' ? (
                <div className={'carouselLiveIndicatorContainer'} style={styles.carouselLiveIndicatorContainer}>
                  <div className={'carouselIndicator'} style={{ ...styles.carouselIndicator, background: '#FF4848' }} />
                  <span className={'carouselIndicatorText'} style={styles.carouselIndicatorText}>
                    live
                  </span>
                </div>
              ) : null}
              {mediaItem?.restrictions?.length &&
              (mediaItem.restrictions.includes(ContentRestrictionsType.Subscription) ||
                mediaItem.restrictions.includes(ContentRestrictionsType.Ppv) ||
                mediaItem.restrictions.includes(ContentRestrictionsType.Bundle)) ? (
                <>
                  <div className={'slideUnpurchasedContainer'} style={styles.slideUnpurchasedContainer} />
                  <div className={'slideUnpurchasedIcon'} />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

const styles = {
  mediaRowContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: '25px 90px',
    position: 'relative',
  } as React.CSSProperties,
  slideContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  } as React.CSSProperties,
  slideLeft: {
    flex: 1,
  } as React.CSSProperties,
  slideRight: {
    boxSizing: 'border-box',
    aspectRatio: '16 / 9',
    padding: '16px 16px 16px 16px',
    overflow: 'visible',
    flex: 1,
  } as React.CSSProperties,
  slideContent: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0 35px',
    overflow: 'auto',
    width: '100%',
  } as React.CSSProperties,
  slideHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: 56,
  } as React.CSSProperties,
  slideBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
    zIndex: 1,
  } as React.CSSProperties,
  btnArrow: {
    width: 0,
    height: 0,
    borderTop: '6px solid transparent',
    borderBottom: '6px solid transparent',
    borderLeft: '13px solid #FFFFFF',
    marginRight: 13,
  } as React.CSSProperties,
  slideImgContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  } as React.CSSProperties,
  slideImg: {
    objectFit: 'contain',
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    filter: 'drop-shadow(7px 7px 5px #00000058)',
  } as React.CSSProperties,
  carouselLiveIndicatorContainer: {
    position: 'absolute',
    top: 20,
    left: 20,
    background: 'rgba(0, 0, 0, 0.5)',
    borderRadius: 10,
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '16px 20px',
    justifyContent: 'center',
    transition: 'all .3s ease-in-out',
  } as React.CSSProperties,
  carouselIndicator: {
    width: 11,
    height: 11,
    borderRadius: '50%',
    marginRight: 15,
  } as React.CSSProperties,
  carouselIndicatorText: {
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 1.8,
    color: '#FFFFFF',
    textTransform: 'uppercase',
  } as React.CSSProperties,
  slideUnpurchasedContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1,
    borderTop: '108px solid #404040',
    borderLeft: '108px solid transparent',
    transition: 'all .2s ease-in-out',
  } as React.CSSProperties,
  slideInfo: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  } as React.CSSProperties,
  slideProgressBarWrapper: {
    margin: '0 auto',
    width: '100%',
    gap: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  } as React.CSSProperties,
  slideProgressBarContainer: {
    display: 'flex',
    gap: 20,
    maxWidth: 350,
    minWidth: 150,
    alignSelf: 'start',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: 'auto',
    width: '100%',
  } as React.CSSProperties,
  slideProgressBarTopText: {
    color: '#FFFFFF',
    fontSize: 13,
    lineHeight: '13px',
    fontWeight: 500,
    width: '100%',
    textAlign: 'left',
  } as React.CSSProperties,
  slideProgressBarText: {
    color: '#808080',
    fontSize: 13,
    lineHeight: '13px',
    fontWeight: 500,
    justifyContent: 'end',
    whiteSpace: 'nowrap',
  } as React.CSSProperties,
  slideUpcomingText: {
    color: '#FFFFFF',
    fontWeight: 500,
    fontSize: 13,
    textAlign: 'left',
    verticalAlign: 'middle',
    minWidth: 100,
  } as React.CSSProperties,
  slideDurationText: {
    color: '#FFFFFF',
    fontWeight: 500,
    fontSize: 13,
    textAlign: 'left',
    verticalAlign: 'middle',
    minWidth: 100,
  } as React.CSSProperties,
}

export default Slide
