import React, { CSSProperties, RefObject, useState } from 'react'
import classNames from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import type { ReactNode } from 'react'
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  computePosition,
  Placement,
} from '@floating-ui/react'
import './index.css'

export interface TooltipProps {
  className?: string
  children?: ReactNode
  style?: CSSProperties
  isOpen?: boolean
  placement?: Placement
  title: string | ReactNode
  wrapperClass?: string
  targetRef?: RefObject<HTMLSpanElement>
}

const Tooltip = (props: TooltipProps) => {
  const { className, children, isOpen = false, placement = 'top', title, wrapperClass, targetRef } = props

  const [tooltipOpen, setTooltipOpen] = useState<boolean>(isOpen)
  const [tooltipPlacement, setTooltipPlacement] = useState<Placement>(placement)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setTooltipOpen,
    placement: tooltipPlacement,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: 'start',
      }),
      shift(),
    ],
  })

  if (tooltipOpen && targetRef?.current && refs?.reference?.current) {
    computePosition(targetRef.current, refs.reference.current as HTMLElement, {
      placement: tooltipPlacement,
      middleware: [
        offset(5),
        flip({
          fallbackAxisSideDirection: 'start',
        }),
        shift(),
      ],
    }).then((position) => {
      if (tooltipPlacement !== position?.placement) {
        setTooltipPlacement(position?.placement)
      }
    })
  }

  const hover = useHover(context, { move: false })
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'tooltip' })

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role])

  return (
    <>
      <span ref={refs.setReference} {...getReferenceProps()} className={classNames('tooltip-wrapper', wrapperClass)}>
        {children}
      </span>
      <FloatingPortal>
        <AnimatePresence>
          <motion.div
            key={'tooltip'}
            className={classNames('tooltip', 'tooltip-' + tooltipPlacement, className)}
            initial={{
              opacity: 0,
              visibility: 'hidden',
            }}
            animate={
              tooltipOpen
                ? {
                    opacity: 1,
                    visibility: 'visible',
                  }
                : {
                    opacity: 0,
                    visibility: 'hidden',
                  }
            }
            transition={{
              duration: tooltipOpen ? 0 : 0.15,
              delay: tooltipOpen ? 1 : 0,
              type: 'tween',
            }}
            ref={refs.setFloating}
            style={{ ...floatingStyles, ...styles.tooltip }}
            {...getFloatingProps()}>
            <span>{title}</span>
          </motion.div>
        </AnimatePresence>
      </FloatingPortal>
    </>
  )
}

const styles = {
  tooltip: {
    zIndex: 5,
    color: '#FFFFFF',
    borderRadius: 6,
    maxWidth: 300,
    padding: '8px 10px',
    background: '#262626 0% 0% no-repeat padding-box',
    boxShadow: '0px 6px 25px #000000BF',
    border: '1px solid #404040',
    backdropFilter: 'blur(30px)',
    WebkitBackdropFilter: 'blur(30px)',
  } as React.CSSProperties,
}

Tooltip.displayName = 'Tooltip'

export default Tooltip
