import DropdownItem from './DropdownItem'
import './DropdownMenu.css'

declare module 'react' {
  function forwardRef<T, P = Record<string, never>>(
    render: (props: P, ref: React.Ref<T>) => React.ReactNode | null,
  ): (props: P & React.RefAttributes<T>) => JSX.Element
}

import React, { useRef, FC, forwardRef, ForwardedRef, MutableRefObject, CSSProperties } from 'react'
import { useSelector } from 'react-redux'
import { selectWindowSize } from '../../store/base/commonSlice'
import { WindowSize } from '../../constants/enums'

type MenuItemProps<T> = {
  item: T
}

type MenuComponents<T> = {
  Item?: FC<MenuItemProps<T>>
}

type DropdownMenuProps<T, Item = T> =
  | {
      heading?: string
      components: MenuComponents<T>
      items: Item[]
      containerStyle?: CSSProperties
      innerContainerStyle?: CSSProperties & {
        mobile?: CSSProperties
      }
      alignment?: 'left' | 'right'
      className?: string
    }
  | {
      heading?: string
      items: Link[]
      containerStyle?: CSSProperties
      innerContainerStyle?: CSSProperties & {
        mobile?: CSSProperties
      }
      alignment?: 'left' | 'right'
      className?: string
    }

function setComponentRefs<T>(ref: MutableRefObject<T | null>, forwardedRef: ForwardedRef<T>) {
  return (el: T) => {
    ref.current = el
    if (typeof forwardedRef === 'function') forwardedRef(el)
    else if (forwardedRef) forwardedRef.current = el
  }
}

function DropdownMenu<T>(
  {
    heading,
    items,
    containerStyle: innerContainerStyle,
    innerContainerStyle: incomingMenuStyle,
    alignment = 'right',
    className,
    ...rest
  }: DropdownMenuProps<T>,
  forwardedRef: ForwardedRef<HTMLDivElement>,
) {
  const windowSize = useSelector(selectWindowSize)

  const containerStyle =
    windowSize === WindowSize.Mobile
      ? {
          ...styles.container,
          ...styles.containerMobile,
        }
      : {
          ...styles.container,
          justifyContent: alignment === 'left' ? 'flex-start' : 'flex-end',
        }

  const containerInnerStyle =
    windowSize === WindowSize.Mobile
      ? {
          ...styles.containerInner,
          ...styles.containerInnerMobile,
          ...innerContainerStyle,
        }
      : {
          ...styles.containerInner,
          ...innerContainerStyle,
        }

  const menuStyle =
    windowSize === WindowSize.Mobile
      ? {
          ...styles.menu,
          ...styles.menuMobile,
          //gap: 20,
          ...incomingMenuStyle,
          ...incomingMenuStyle?.mobile,
        }
      : {
          ...styles.menu,
          //gap: 38,
          ...incomingMenuStyle,
        }

  const ref = useRef<HTMLDivElement>(null)
  return (
    <div
      className={`dropdown-menu dropdown-hidden ${className || ''}`}
      ref={setComponentRefs(ref, forwardedRef)}
      style={containerStyle}
      onClick={() => {
        ref.current?.classList.toggle('dropdown-hidden')
      }}>
      <div
        style={{ display: 'flex', flexDirection: 'column', width: windowSize === WindowSize.Mobile ? '100%' : 'auto' }}>
        <div className={'dropdown-inner'} style={containerInnerStyle}>
          {heading ? (
            <div style={styles.header}>
              <span>{heading}</span>
              <div style={styles.headerLine} />
            </div>
          ) : null}
          <div style={menuStyle}>
            {items.map((item, index) => {
              if ('components' in rest) {
                if (rest.components.Item) {
                  return <rest.components.Item item={item as T} key={index} />
                }
              }
              return <DropdownItem item={item as Link} key={index} />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

const styles: CSSObject = {
  container: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    paddingTop: 11,
  },
  containerMobile: {
    width: '100%',
    justifyContent: 'center',
    position: 'fixed',
    top: 54,
    paddingTop: 6,
    bottom: 0,
    right: 0,
    left: 0,
  },
  containerInner: {
    zIndex: 5,
    width: 'auto',
    overflow: 'scroll',
    padding: 40,
    paddingTop: 38,
    paddingBottom: 38,
    paddingLeft: 40,
    paddingRight: 40,
    background: 'rgba(38,38,38,0.75) 0% 0% no-repeat padding-box',
    boxShadow: '0px 6px 25px #000000BF',
    border: '1px solid #404040',
    borderRadius: 5,
    opacity: 1,
    backdropFilter: 'blur(30px)',
    WebkitBackdropFilter: 'blur(30px)',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },
  containerInnerMobile: {
    borderRadius: 10,
    left: 0,
    right: 0,
    height: 'auto',
    minWidth: 'auto',
    padding: 25,
    paddingTop: 18,
    paddingBottom: 18,
    paddingLeft: 25,
    paddingRight: 25,
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 15,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '12px',
    font: 'SF Pro Text',
    fontSize: 10,
    fontWeight: 600,
    textTransform: 'uppercase',
    color: 'white',
    textAlign: 'left',
    letterSpacing: 1,
    marginBottom: 18.5,
    gap: 8,
  },
  headerLine: {
    flex: 1,
    height: 1,
    backgroundColor: '#404040',
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    gap: 26,
  },
  menuMobile: {},
}
export default forwardRef(DropdownMenu)
