import React, { useMemo, useRef } from 'react'

import {
  Menu,
  Tooltip,
  useCaptionOptions,
  MenuPlacement,
  TooltipPlacement,
  IconComponent,
  useVideoQualityOptions,
  MenuInstance,
  VideoQualityOption,
} from '@vidstack/react'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ClosedCaptionsIcon,
  SettingsIcon,
  SettingsMenuIcon,
} from '@vidstack/react/icons'

export interface SettingsProps {
  placement: MenuPlacement
  tooltipPlacement: TooltipPlacement
}

export function Settings({ placement, tooltipPlacement }: SettingsProps) {
  const menu = useRef<MenuInstance>(null)

  const onSelectOption = () => {
    menu.current?.close()
  }

  return (
    <Menu.Root ref={menu} className="vds-menu">
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <Menu.Button className="vds-menu-button vds-button">
            <SettingsIcon className="vds-rotate-icon" />
          </Menu.Button>
        </Tooltip.Trigger>
        <Tooltip.Content className="vds-tooltip-content" placement={tooltipPlacement}>
          Settings
        </Tooltip.Content>
      </Tooltip.Root>
      <Menu.Content className="vds-menu-items" placement={placement}>
        <CaptionSubmenu onSelectOption={onSelectOption} closeOnSelect />
        <VideoQualitySubmenu onSelectOption={onSelectOption} closeOnSelect />
      </Menu.Content>
    </Menu.Root>
  )
}

type SubmenuProps = {
  onSelectOption: (trigger?: Event) => void
  closeOnSelect?: boolean
}

function CaptionSubmenu({ onSelectOption, closeOnSelect }: SubmenuProps) {
  const options = useCaptionOptions(),
    hint = options.selectedTrack?.label ?? 'Off'
  const menu = useRef<MenuInstance>(null)
  return (
    <Menu.Root ref={menu}>
      <SubmenuButton label="Captions" hint={hint} disabled={options.disabled} icon={ClosedCaptionsIcon} />
      <Menu.Content className="vds-menu-items">
        <Menu.RadioGroup className="vds-radio-group" value={options.selectedValue}>
          {options.map(({ label, value, select }) => (
            <Menu.Radio
              className="vds-radio"
              value={value}
              onSelect={(event) => {
                select(event)
                if (closeOnSelect) {
                  menu.current?.close()
                }
                onSelectOption(event)
              }}
              key={value}>
              <div className="vds-radio-check" />
              <span className="vds-radio-label">{label}</span>
            </Menu.Radio>
          ))}
        </Menu.RadioGroup>
      </Menu.Content>
    </Menu.Root>
  )
}

function VideoQualitySubmenu({ onSelectOption, closeOnSelect }: SubmenuProps) {
  const options = useVideoQualityOptions({ auto: true, sort: 'descending' }),
    currentQualityHeight = options.selectedQuality?.height,
    hint =
      options.selectedValue !== 'auto' && currentQualityHeight
        ? `${currentQualityHeight}p`
        : `Auto${currentQualityHeight ? ` (${currentQualityHeight}p)` : ''}`
  const menu = useRef<MenuInstance>(null)

  const uniqueResolutionOptions = useMemo(() => {
    const retOptions: VideoQualityOption[] = []
    options.forEach((option) => {
      if (!retOptions.some((opt) => opt.label === option.label)) {
        retOptions.push(option)
      }
    })
    return retOptions
  }, [options])

  return (
    <Menu.Root ref={menu}>
      <SubmenuButton label={'Quality'} hint={hint} disabled={options.disabled} icon={SettingsMenuIcon} />
      <Menu.Content className={'vds-menu-items'}>
        <Menu.RadioGroup className={'vds-radio-group'} value={options.selectedValue}>
          {uniqueResolutionOptions.map(({ label, value, select }) => (
            <Menu.Radio
              className={'vds-radio'}
              value={value}
              onSelect={(event) => {
                select(event)
                if (closeOnSelect) {
                  menu.current?.close()
                }
                onSelectOption(event)
              }}
              key={value}>
              <div className="vds-radio-check" />
              <span className="vds-radio-label">{label}</span>
            </Menu.Radio>
          ))}
        </Menu.RadioGroup>
      </Menu.Content>
    </Menu.Root>
  )
}

export interface SubmenuButtonProps {
  label: string
  hint: string
  disabled?: boolean
  icon: IconComponent
}

function SubmenuButton({ label, hint, icon: Icon, disabled }: SubmenuButtonProps) {
  return (
    <Menu.Button className="vds-menu-button" disabled={disabled}>
      <ChevronLeftIcon className="vds-menu-button-close-icon" />
      <Icon className="vds-menu-button-icon" />
      <span className="vds-menu-button-label">{label}</span>
      <span className="vds-menu-button-hint">{hint}</span>
      <ChevronRightIcon className="vds-menu-button-open-icon" />
    </Menu.Button>
  )
}
