import React from 'react'
import Dropdown from '../../../common/Dropdown'
import { WindowSize } from '../../../constants/enums'
import DropdownIcon from '../../../assets/DropdownArrow.svg'
import MoreIconMobile from '../../../assets/MoreIconMobile.svg'
import { useSelector } from 'react-redux'
import { selectWindowSize } from '../../../store/base/commonSlice'

type MoreDropdownWrapperProps = {
  //open: boolean
  items: Link[]
}

const MoreDropdown = (props: MoreDropdownWrapperProps) => {
  const { items } = props

  const windowSize = useSelector(selectWindowSize)

  const moreButtonWrapperStyle =
    windowSize === WindowSize.Mobile
      ? { ...styles.moreButtonWrapper, ...styles.moreButtonWrapperMobile }
      : { ...styles.moreButtonWrapper }

  const menuContainerStyle =
    windowSize === WindowSize.Mobile ? { ...styles.menuContainerMobile } : { ...styles.menuContainer }

  return (
    <Dropdown>
      <div style={moreButtonWrapperStyle}>
        {windowSize !== WindowSize.Mobile ? (
          <>
            <div style={styles.moreButton}>More</div>
            <img src={DropdownIcon} alt={'dropdown'} />
          </>
        ) : (
          <img style={styles.mobileIcon} src={MoreIconMobile} alt={'icon'} />
        )}
      </div>
      <Dropdown.Menu
        items={items}
        alignment={'left'}
        containerStyle={menuContainerStyle}
        innerContainerStyle={styles.menuInnerContainer}
        className="hide-scrollbar"
      />
    </Dropdown>
  )
}

const styles: CSSObject = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  menuContainer: {
    alignSelf: 'start',
    maxHeight: 540,
  },
  menuContainerMobile: {
    maxHeight: 560,
  },
  menuInnerContainer: {
    overflow: 'hidden',
  },
  moreButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    //paddingBottom: 14,
    cursor: 'pointer',
  },
  mobileIcon: {
    height: 17,
    padding: 10,
  },
  moreButtonWrapperMobile: {
    //width: 4,
    //marginTop: 17,
  },
  moreButton: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
    marginRight: 10,
    lineHeight: '21px',
  },
  dropdownMenuMobile: {
    position: 'absolute',
    top: 60,
    left: 0,
  },
}

export default MoreDropdown
